/* eslint-disable no-undef */
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { AuthContext } from '../context/auth';
import Grid from '@mui/material/Grid';

import BigText from '../components/BigText.jsx';
import Button from '../components/ButtonS.jsx';
import DynamicFontText from '../components/DynamicFontText.jsx';
import Error from '../components/Error.jsx';
import Loader from '../components/Loader.jsx';
import Title from '../components/Title.jsx';
import Statistics from '../components/Statistics.jsx';

import { fetchApiMethods } from '../api/getMethods';

import { UserContext } from '../context/user.js';

const Profile = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { setIsAuth } = useContext(AuthContext);

  const { setId, autoSchedule, setAutoSchedule } =
    React.useContext(UserContext);
  const [initialAutoSchedule, setInitialAutoSchedule] = useState();

  const [api, setApi] = useState({});
  const [processing, setProcessing] = useState(false);
  const [expireTitle, setExpireTitle] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [premiumTitle, setPremiumTitle] = useState('');
  const [premium, setPremium] = useState(false);
  const [expireDate, setExpireDate] = useState('');
  const [expireColor, setExpireColor] = useState('');
  const [updatedDetailsAt, setUpdatedDetailsAt] = useState();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const api = await fetchApiMethods();
        setApi(api);
        const { user } = await api.user.get();
        const { status } = await api.user.getStatus();
        const { status: paymentStatus } = await api.user.getPaymentStatus();
        setProcessing(paymentStatus && paymentStatus !== 'COMPLETE' && true);

        let premiumTitle;
        if (status.premium) {
          setPremium(true);
          premiumTitle = intl.formatMessage({ id: 'Premium' });
          if (status.lifetime) {
            premiumTitle += ` ${intl.formatMessage({ id: 'Lifetime' })}`;
          }
        }

        if (!status.lifetime && status.expiredAt) {
          setExpireTitle(
            intl.formatMessage({ id: status.premium ? 'Expires' : 'Expired' }),
          );
          setExpireColor(status.premium ? 'max' : 'red');
        }

        setAutoSchedule(status.autoDetailsUpdate);
        setInitialAutoSchedule(status.autoDetailsUpdate);

        setExpireDate(
          status.expiredAt &&
            new Date(status.expiredAt).toISOString().split('T')[0],
        );
        setPremiumTitle(premiumTitle);
        setUpdatedDetailsAt(status.updatedDetailsAt);
        setId(user.id);

        if (user && user.email) {
          setEmail(user.email);
          localStorage.setItem('email', user.email);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        navigate(`/error`);
      }
    }
    fetchData();
  }, []);

  if (loading) return <Loader />;

  const handleBackClick = async () => {
    if (initialAutoSchedule !== autoSchedule)
      await api.user.update({ autoDetailsUpdate: autoSchedule });
    navigate('/menu');
  };
  const handleExitClick = () => {
    setIsAuth(false);
    localStorage.removeItem('token');
  };

  // eslint-disable-next-line no-unused-vars
  const handlePaymentsClick = async () => {
    navigate(`/payments`);
  };

  return (
    <Grid container spacing={2}>
      <Title text={<FormattedMessage id="Profile" />} />
      <Grid item xs={12} textAlign={'center'}>
        <DynamicFontText
          text={premiumTitle}
          color={'premium'}
          fontSize="12px"
        />
        <BigText text={email} />
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={6} textAlign={'right'}>
            <DynamicFontText
              text={expireTitle}
              color={'primary'}
              fontSize="12px"
            />
          </Grid>
          <Grid item xs={6} textAlign={'left'}>
            <DynamicFontText
              text={expireDate}
              color={expireColor}
              fontSize="13px"
            />
          </Grid>
        </Grid>
        {!premium && (
          <Grid item xs={12} textAlign={'center'} style={{ marginTop: '10px' }}>
            {processing ? (
              <Error text={<FormattedMessage id="ProcessingOrder" />} />
            ) : (
              <Button onClick={handlePaymentsClick}>
                {<FormattedMessage id="Upgrade" />}
              </Button>
            )}
          </Grid>
        )}
        <Statistics
          api={api}
          premium={premium}
          updatedDetailsAt={updatedDetailsAt}
        />
      </Grid>
      <Grid item xs={12} textAlign={'center'} paddingBottom={3}>
        <Button onClick={handleExitClick}>
          {<FormattedMessage id="Logout" />}
        </Button>
      </Grid>
      <Grid item xs={12} textAlign={'center'}>
        <Button onClick={handleBackClick}>
          {<FormattedMessage id="Back" />}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Profile;
