import React, { useState } from 'react';
import { CssBaseline } from '@mui/material';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

import DateForm from './DateForm/DateForm.jsx';

import css from '../themes/styles.js';

const Title = ({
  isModal = false,
  color,
  extraColor = color,
  inputDate,
  text,
  extraText,
  editButton,
  arrowButtons,
  handleEditClick,
  handleLeftClick,
  handleRightClick,
  handleGoDate,
}) => {
  let disabled = true;

  const [modal, setModal] = useState(false);

  if (arrowButtons) {
    const date = new Date(inputDate || text).toLocaleDateString();
    const now = new Date().toLocaleDateString();
    disabled = date === now;
  }

  const handleModalClick = async (date) => {
    handleGoDate(date);
  };

  return (
    <Grid
      item
      xs={12}
      textAlign={'center'}
      sx={{ ...css.gridCenter, ...css.gridTitle, height: '2vh', color }}
    >
      <DateForm
        visible={modal}
        setVisible={setModal}
        handleModalClick={handleModalClick}
      />
      <CssBaseline />
      {arrowButtons ? (
        <IconButton
          color="primary"
          sx={{ marginLeft: '15px', marginTop: '4px' }}
          onClick={handleLeftClick}
        >
          <ArrowLeft />
        </IconButton>
      ) : null}
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (isModal) setModal(true);
        }}
        sx={{ fontSize: '24px', fontWeight: 'bold' }}
      >
        {text}
      </Typography>
      {extraText ? (
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          color={extraColor}
          sx={{ fontSize: '24px', fontWeight: 'bold' }}
        >
          &nbsp;{extraText}
        </Typography>
      ) : null}
      {editButton ? (
        <IconButton
          color="primary"
          sx={{ marginLeft: '15px', marginTop: '4px' }}
          onClick={handleEditClick}
        >
          <EditIcon />
        </IconButton>
      ) : null}
      {arrowButtons && !disabled ? (
        <IconButton
          color="primary"
          sx={{ marginLeft: '4px', marginTop: '4px' }}
          onClick={handleRightClick}
        >
          <ArrowRight />
        </IconButton>
      ) : null}
    </Grid>
  );
};

export default Title;
