import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import packageJson from '../../package.json';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import Button from '../components/ButtonS.jsx';
import Title from '../components/Title.jsx';

import { AppContext } from '../context/app.js';
import { fetchApiMethods } from '../api/getMethods.js';

import css from '../themes/styles.js';

const fontSize = '14px';

const Settings = () => {
  const {
    themeTitle,
    toggleTheme,
    locale,
    toggleLocale,
    defaultWeekDay,
    toggleDefaultDayOfWeek,
  } = useContext(AppContext);
  const navigate = useNavigate();

  const handleToggleChange = async (locale) => {
    toggleLocale(locale);
    const api = await fetchApiMethods();
    await api.user.update({ locale });
  };

  return (
    <Grid container spacing={2}>
      <Title text={<FormattedMessage id="Settings" />} />

      <Grid item xs={3} />
      <Grid item xs={3} sx={{ ...css.gridRight }}>
        <FormControl>
          <FormLabel id="theme-radio-buttons">
            <FormattedMessage id="Theme" />
          </FormLabel>
          <RadioGroup
            aria-labelledby="theme-radio-buttons"
            value={themeTitle}
            name="radio-buttons-group"
            row
          >
            <FormControlLabel
              value="darkTheme"
              control={<Radio onChange={() => toggleTheme('darkTheme')} />}
              label={<FormattedMessage id="DarkTheme" />}
            />
            <FormControlLabel
              value="lightTheme"
              control={<Radio onChange={() => toggleTheme('lightTheme')} />}
              label={<FormattedMessage id="LightTheme" />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl>
          <FormLabel id="language-radio-buttons">
            <FormattedMessage id="Language" />
          </FormLabel>
          <RadioGroup
            aria-labelledby="language-radio-buttons"
            value={locale}
            name="radio-buttons-group"
            row
          >
            <FormControlLabel
              value="en"
              control={<Radio onChange={() => handleToggleChange('en')} />}
              label={<FormattedMessage id="EN" />}
            />
            <FormControlLabel
              value="ru"
              control={<Radio onChange={() => handleToggleChange('ru')} />}
              label={<FormattedMessage id="RU" />}
            />
            <FormControlLabel
              value="uk"
              control={<Radio onChange={() => handleToggleChange('uk')} />}
              label={<FormattedMessage id="UA" />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={3} />

      <Grid item xs={3} />
      <Grid item xs={6} textAlign={'center'}>
        <Typography
          gutterBottom
          color="details"
          sx={{ fontSize, fontWeight: 'bold' }}
        >
          {<FormattedMessage id="Statistics" />}
        </Typography>
        <FormControl>
          <FormLabel id="theme-radio-buttons">
            <FormattedMessage id="FirstDayOfTheWeek" />
          </FormLabel>
          <RadioGroup
            aria-labelledby="theme-radio-buttons"
            value={defaultWeekDay}
            name="radio-buttons-group"
            row
          >
            <FormControlLabel
              value="1"
              control={<Radio onChange={() => toggleDefaultDayOfWeek('1')} />}
              label={<FormattedMessage id="Monday" />}
            />
            <FormControlLabel
              value="6"
              control={<Radio onChange={() => toggleDefaultDayOfWeek('6')} />}
              label={<FormattedMessage id="Saturday" />}
            />
            <FormControlLabel
              value="0"
              control={<Radio onChange={() => toggleDefaultDayOfWeek('0')} />}
              label={<FormattedMessage id="Sunday" />}
            />
            <FormControlLabel
              value="7"
              control={<Radio onChange={() => toggleDefaultDayOfWeek('7')} />}
              label={<FormattedMessage id="ByDays" />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={3} />

      <Grid item xs={12} textAlign={'center'}>
        <Button onClick={() => navigate('/menu')}>
          {<FormattedMessage id="Back" />}
        </Button>
      </Grid>

      <Grid item xs={12} textAlign={'center'}>
        <p>
          {<FormattedMessage id="Version" />}: {packageJson.version}
        </p>
      </Grid>
    </Grid>
  );
};

export default Settings;
