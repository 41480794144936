import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  Tooltip as MaterialTooltip,
} from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { styled } from '@mui/system';

const color = '#00c621';

const TooltipInPremium = styled(({ className, ...props }) => (
  <MaterialTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .MuiTooltip-tooltip`]: {
    background: 'linear-gradient(45deg, #388e3c, #81c784)',
    color: '#FF0',
    borderRadius: '8px',
    padding: '5px',
    fontSize: '1rem',
  },
}));

const Chart = ({
  coefficient,
  title,
  data,
  perDayTitle,
  premium,
  range,
  toggleRange,
  visibleArrowRight,
  setVisibleArrowRight,
}) => {
  return (
    <Grid container sx={{ marginBottom: '20px' }}>
      <Grid
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        sx={{ display: 'flex' }}
      >
        <TooltipInPremium
          title={!premium ? <FormattedMessage id="AvailableInPremium" /> : ''}
          disableInteractive
        >
          <span>
            <IconButton
              disabled={!premium}
              color="primary"
              sx={{ marginLeft: '30px', marginTop: '4px', marginBottom: '5px' }}
              onClick={async () => {
                const newCoefficient = coefficient + 1;
                if (newCoefficient > 1) setVisibleArrowRight(true);
                await toggleRange(range, newCoefficient);
              }}
            >
              <ArrowLeft />
            </IconButton>
          </span>
        </TooltipInPremium>
        <Typography
          color="details"
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        {visibleArrowRight && (
          <IconButton
            color="primary"
            sx={{ marginTop: '4px', marginBottom: '5px' }}
            onClick={async () => {
              const newCoefficient = coefficient - 1;
              await toggleRange(range, newCoefficient);
              if (newCoefficient === 1) setVisibleArrowRight(false);
            }}
          >
            <ArrowRight />
          </IconButton>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        sx={{
          display: 'flex',
          width: '100%',
          maxWidth: '620px',
          maxHeight: '380px',
          aspectRatio: '620 / 380',
        }}
      >
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip labelStyle={{ color }} />
            <Legend />
            <Line
              type="monotone"
              dataKey={perDayTitle}
              stroke={color}
              activeDot={{ r: 5 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={6} textAlign={'center'}>
        <FormControl>
          <RadioGroup
            aria-labelledby="theme-radio-buttons"
            value={range}
            name="radio-buttons-group"
            row
          >
            <TooltipInPremium
              title={
                !premium ? <FormattedMessage id="AvailableInPremium" /> : ''
              }
              disableInteractive
            >
              <span>
                <FormControlLabel
                  value="1"
                  control={<Radio onChange={() => toggleRange(1)} />}
                  label={<FormattedMessage id="Week" />}
                />
                <FormControlLabel
                  disabled={!premium}
                  value="2"
                  control={<Radio onChange={() => toggleRange(2)} />}
                  label={<FormattedMessage id="Month" />}
                />
                <FormControlLabel
                  disabled={!premium}
                  value="3"
                  control={<Radio onChange={() => toggleRange(3)} />}
                  label={<FormattedMessage id="Year" />}
                />
              </span>
            </TooltipInPremium>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
};

export default Chart;
