const ru = {
  About: 'О нас',
  AboutAnyQuestionsTitle:
    'Если у Вас есть вопросы или предложения, пожалуйста, свяжитесь с нами по электронной почте',
  AboutAnyQuestionsDescription:
    'Мы ценим вашу обратную связь и постараемся ответить на ваше сообщение в течение разумного времени. Пожалуйста, имейте в виду, что из-за большой загрузки, ответ может занять несколько дней. Мы приносим извинения за любые неудобства и благодарим Вас за понимание',
  AccountDeletionRequest: 'Запрос на удаление аккаунта',
  Add: 'Добавить',
  AddItem: 'Добавить активность',
  AdditionalInfo: 'Дополнительная информация',
  AddSection: 'Добавить группу',
  Ago: 'назад',
  Agree: 'Приступая к работе, вы соглашаетесь с нашими',
  AlreadyExists: 'Такая категория уже существует',
  And: ' и ',
  AndroidApp: 'Приложение для Android',
  AppTitle: 'Навигатор Активности',
  Assessment: 'Оценка',
  AssignedDeviceTitle: 'УСТРОЙСТВО БЫЛО ЗАКРЕПЛЕНО ЗА ЭЛЕКТРОННОЙ ПОЧТОЙ',
  AssignedDeviceBody1:
    'Вы пытаетесь назначить нового пользователя на это устройство.',
  AssignedDeviceBody2:
    'В этом случае вся информация для предыдущего пользователя с',
  AreYouSure: 'Вы уверены, что хотите этого?',
  AreYouSureAccountDeletionRequest:
    'Вы уверены, что хотите удалить свою учетную запись? На ваш зарегистрированный адрес электронной почты будет отправлено письмо с подтверждением',
  AutomaticUpdate: 'Автоматическое обновление',
  Back: 'Назад',
  Basic: 'Базовый',
  Benefits: 'Преимущества',
  BenefitsEarlyAccess: 'Ранний доступ к новым функциям',
  BenefitsExchangeDescription:
    'Вы сможете легко собрать свои данные из других ресурсов в один .xlsx файл и одним кликом импортировать данные в приложение',
  BenefitsExchangeTitle: 'Импорт и Экспорт данных в формате Excel',
  BenefitsExchangeWarning:
    'Во время Импорта все данные из приложения удаляются, поэтому необходимо делать Експорт и добавлять данные в сгенерированный файл. Так же не рекомендуется удалять системные данные которые прописались в ексель файле, это может привести к ошибкам во время Импорта',
  BenefitsInstantStatisticDescription:
    'Для обычных пользователей статистика обновляется раз в неделю. Для премиум пользователей это ограничение снято',
  BenefitsInstantStatisticsUpdate: 'Мгновенное обновление статистики',
  BuyCoffee: 'На кофе',
  ByDays: 'По дням',
  Cancel: 'Отмена',
  CardNo: 'Номер карты',
  CardExpirationDate: 'Cрок действия (ММ/ГГ)',
  CardInvalidCode: 'Введите 16 цифр',
  CardInvalidExpiration: 'Введите ММ/ГГ',
  CardInvalidCVV: 'Введите 3 цифры',
  ChooseFile: 'Выберите файл',
  Code: 'Код',
  CodeCVV: 'CVV/CVC код',
  CodeIncorrect: 'Неверный код. Пожалуйста, попробуйте еще раз',
  Confirm: 'Подтвердить',
  ConfirmPassword: 'Подтвердить пароль',
  Contribute: 'Внести свой вклад',
  ConsecutiveDays: 'дней подряд',
  Count: 'Количество',
  CreateProfile: 'Регистрация',
  Crossed: 'пересечения',
  CurrentWeek: 'Текущая неделя',
  DarkTheme: 'Темная',
  day: 'день',
  days: 'дня',
  days2: 'дней',
  dayAgo: 'вчера',
  daysAgo: 'дня назад',
  DaysAgo: 'Дней назад',
  Delete: 'Удалить',
  DeleteAccount: 'Удалить аккаунт',
  Details: 'Детальнее',
  Done: 'Выполнено',
  done: 'выполнено',
  DoneConsecutiveDays: 'Выполнено дней подряд',
  Download: 'Скачать',
  EN: 'Английский',
  ENMobile: 'Англ.',
  Enable: 'Включить',
  EnterCode: 'Введите код, отправленный на вашу электронную почту',
  Email: 'почта',
  EmailAddress: 'Почта',
  EmailCheck:
    'Пожалуйста, проверьте свой почтовый ящик, чтобы установить новый пароль',
  EmailInUse: 'Этот электронный адрес уже используется',
  EmailInvalid: 'Недопустимый адрес электронной почты',
  EmailNotFound: 'Пользователь с таким адресом электронной почты не найден',
  Error: 'Ошибка',
  ErrorBody:
    'Сервис временно недоступен, возможные причины: техническое обслуживание, данный функционал перестал работать, отсутствие интернета',
  Exit: 'Выход',
  Expires: 'срок действия до',
  Expired: 'срок действия истек',
  ExpiredLink: 'Ссылка устарела',
  Export: 'Экспорт',
  FileHasBeenImported: 'Файл был успешно импортирован',
  Finish: 'Завершить',
  FirstDayOfTheWeek: 'Первый день недели',
  GetStarted: 'Начать',
  GoToDate: 'Укажите дату (дд.мм.гггг)',
  GoTo: 'Перейти',
  GroupTitle: 'Назовите группу',
  Home: 'На главную',
  Hours: 'часы',
  HiddenItems: 'Скрытые активности',
  Import: 'Импорт',
  IncorrectLoginInfo: 'Имя пользователя или пароль указаны неверно',
  InCrypto: 'Криптой',
  InitialSettings: 'Настройки по умолчанию',
  ItemDescription: 'Описание активности',
  ItemForm: 'Редактирование активности',
  ItemTargetValue: 'Желаемое количество в день',
  ItemTitle: 'Название активности',
  Items: 'Активности',
  Language: 'Язык',
  LastDate: 'Последний раз',
  Latest: 'Последний раз',
  LatestUpdate: 'Последнее обновление',
  Lifetime: 'НАВСЕГДА',
  LightTheme: 'Светлая',
  Loading: 'Загрузка...',
  LoginPage: 'Страница входа',
  Logout: 'Выход',
  MB: 'МБ',
  max: 'макс.',
  maxPerDay: 'макс. за день',
  maximum: 'максимум',
  Maximum: 'Максимум',
  MaximumPerDay: 'Максимум в день',
  Menu: 'Меню',
  minimum: 'минимум',
  Minimum: 'Минимум',
  MinimumPerDay: 'Минимум в день',
  Minutes: 'минуты',
  Missed: 'Пропущено',
  MissedConsecutiveDays: 'Пропущенно дней подряд',
  Mobile: 'мобильный',
  Monday: 'Понедельник',
  Month: 'Месяц',
  month: 'месяц',
  months: 'месяца',
  months2: 'месяцев',
  More: 'более',
  moreDaysAgo: 'дней назад',
  Negative: 'Негативная',
  Next: 'Дальше',
  NewSection: 'Новая группа',
  No: 'Нет',
  NoConnection: 'Нет соединения с сервером',
  NoDetails: 'Недостаточно данных для отображения',
  NoItems: 'Вы ещё ничего не добавляли',
  None: 'Не выбрано',
  NoSection: 'Без группы',
  Of: 'из',
  Ok: 'Хорошо',
  OpenWebsite: 'Открыть веб-версию',
  OneMoreDeviceDetected: 'Обнаружено ещё одно устройство',
  OneMoreDeviceDetectedBody1:
    'Обратите внимание, что эта версия поддерживает синхронизацию только с одним устройством. Вам доступны следующие варианты:',
  Password: 'Пароль',
  PasswordNotMatching: 'Пароли не совпадают',
  Payment: 'Оплата',
  PerDay: 'за день',
  PerMonth: 'на один месяц',
  PerMonthForDetailsPage: 'за месяц',
  PleaseWait: 'Пожалуйста подождите...',
  Positive: 'Позитивная',
  Premium: 'Премиум',
  ProcessingOrder: 'Ваш заказ обрабатывается',
  Proceed: 'Продолжить',
  Profile: 'Профиль',
  PrivacyPolicy: 'Политикой конфиденциальности',
  Purchase: 'Купить',
  Records: 'записей',
  Refresh: 'Обновить',
  ReleaseDate: 'Дата выхода',
  RegisterText1_guarantee_delivery:
    'Чтобы гарантировать доставку наших писем, добавьте',
  RegisterText2_contact_list_spam:
    'в список контактов вашей почтовой программы. Это поможет избежать попадания писем в папку "Спам".',
  RegisterText3_After_adding_email:
    'После добавления данного адреса и нажатия на кнопку "Дальше" ниже на этой форме, следуйте инструкциям, чтобы завершить процесс регистрации.',
  RegisterText4_you_can_login:
    'После регистрации появится доступ и на наш веб-сайт:',
  RegisterText5_different_approach:
    'Функционал веб-сайта и приложения различаются, тем самым внося разнооборазия для выполнения повседневных задач.',
  RegisterText6_ask_questions:
    'Если у вас возникнут вопросы, пожалуйста, свяжитесь с нами по электронной почте',
  RegistrationSuccessful: 'Поздравляем, регистрация прошла успешно',
  Remove: 'Удалить',
  RemoveSectionAllow:
    'Вы уверены, что данная группа вам больше не понадобится?',
  RemoveSectionDeny:
    'Эта группа не может быть удалена, пока к ней привязаны активности',
  RemoveDevice: 'Удалить устройство',
  RemovedItem: 'Активность удалена',
  ResetPassword: 'Сброс пароля',
  ResetPasswordForm: 'Сброс пароля',
  RequiredField: 'Поле обязательно для заполнения',
  RU: 'Русский',
  RUMobile: 'Рус.',
  Saturday: 'Суббота',
  Save: 'Сохранить',
  ScheduledUpdate: 'Обновление по расписанию',
  Seconds: 'секунды',
  Section: 'Группа',
  Sections: 'Группы',
  SelectDate: 'Выберите дату',
  SelectExisting: 'Или выберите существующее значение',
  Settings: 'Настройки',
  SignIn: 'Войти',
  Size: 'Размер',
  Synchronization: 'Синхронизация',
  Synchronize: 'Синхронизировать',
  Synchronized: 'Синхронизированы',
  SyncDate: 'Дата синхронизации',
  SyncEraseAllData:
    'При синхронизации с новым устройством существующие данные на устройстве будут удалены',
  SyncEraseExistingData:
    'При включении синхронизации локальные данные на устройстве будут удалены',
  SyncProcess:
    'Происходит синхронизация с мобильным устройством, это не должно занять много времени',
  SyncReqTitle: 'Необходима синхронизация',
  SyncNeedErrorBody:
    'По соображениям безопасности данных, сайтом нельзя пользоваться, пока не будет выполнена синхронизация c мобильным устройством. Пожалуйста, запустите/перезапустите приложение на мобильном устройстве и дождитесь окончания синхронизации. Если синхронизация не нужна её можно выключить с помощью кнопки "Удалить устройство"',
  SyncNeedsToBeDone:
    'Синхронизация должна быть завершена перед использованием приложения',
  SyncToMobileTitle: 'Синхронизация с мобильным устройством',
  SyncToServerTitle: 'Синхронизация с веб-сайтом',
  SpamFolder:
    "Если вы не видите письмо в течение долгого времени, пожалуйста, проверьте папку 'Спам'",
  Started: 'Первый раз',
  Statistics: 'Статистика',
  status: 'статус',
  Sunday: 'Воскресенье',
  Syncing: 'Синхронизация',
  SyncFromServerToDevice: 'синхронизацию с сервером на это устройство',
  Switch: 'Переключить',
  target: 'цель',
  TargetAsNumber: 'Желаемо количество должно быть числовым',
  TargetDone: 'Цель достигнута',
  TargetNotDone: 'Цель не достигнута',
  TermsOfService: 'Условиями предоставления услуг',
  Theme: 'Тема',
  TitleLength: 'Заголовок превышает максимальную длину',
  Today: 'Сегодня',
  Total: 'Всего',
  TryAgain: 'Ещё раз',
  TurnOffSync: 'Отключить синхронизацию',
  Type: 'Вид',
  TypeValueConsistent: 'Нельзя менять текст на число и наоборот',
  UA: 'Украинский',
  UAMobile: 'Укр.',
  UpdateNow: 'Обновить сейчас',
  Upgrade: 'Улучшить',
  Unlock: 'Разблокировать',
  Use: 'Использовать',
  UseDeviceWithoutSync: 'это устройство без синхронизации',
  ValidationStage: 'Стадия Проверки',
  Values: 'Значения',
  VerifiedBy: 'Проверено',
  Warning: 'Предупреждение!',
  Website: 'вебсайт',
  Week: 'Неделя',
  WillBeRemoved: 'будет удалено',
  Year: 'Год',
  year: 'год',
  years: 'года',
  years2: 'лет',
  YesDelete: 'Да, удалить',
  minutes: 'минуты',
  number: 'число',
  seconds: 'секунды',
  text: 'текст',
  Close: 'Закрыть',
  UpdateVersionTitle: 'Пожалуйста, обновитесь до новой версии',
  WhatsNew: 'Что нового?',
  Version: 'Версия',
  AvailableInPremium: 'Доступно в премиум',
  GoToMobDate: 'Перейти',
  SortByActivity: 'Сортировка по активности',
  Top: 'Наверх',
  Bottom: 'Вниз',
  step: 'шаг',
};

export default ru;
