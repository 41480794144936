import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchApiMethods } from '../api/getMethods';
import { Button, Grid2, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Title from '../components/Title';
import { AppContext } from '../context/app.js';

const ReleaseNotes = ({ incomingLocale }) => {
  const [files, setFiles] = useState([]);
  const [platform, setPlatform] = useState('web');
  const { type, locale } = useParams();
  const { locale: contextLocale, toggleLocale } = useContext(AppContext);
  const [selectedLocale] = useState(incomingLocale || locale || contextLocale);
  useEffect(() => {
    async function fetchData() {
      try {
        const api = await fetchApiMethods();
        let internalType;
        if (type) {
          internalType = 'mob';
          setPlatform('mob');
        } else internalType = 'web';
        // console.log('internalType', internalType); // TODO DEBUG TEMP LINE
        toggleLocale(selectedLocale);
        const resWithFiles = await api.notes.getListVersionNames({
          type: internalType,
        });
        setFiles(resWithFiles.htmlFiles);
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, []);
  const onClickFile = async (htmlName) => {
    const path = `/resources/${incomingLocale || selectedLocale}/${platform}/${htmlName}`;
    window.open(path, '_blank');
  };

  return (
    <Grid2 container spacing={2} justifyContent="center">
      <Grid2 item="true" size={12}>
        <Title text={<FormattedMessage id="WhatsNew" />} />
      </Grid2>
      <Grid2 item="true" size={1} />
      <Grid2
        item="true"
        size={10}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {files?.length > 0 &&
          files.map((file) => {
            const [dateRaw, version] = file.replace('.html', '').split('_');
            const dateFormatted = dateRaw.replace(
              /(\d{4})(\d{2})(\d{2})/,
              '$1-$2-$3',
            );
            return (
              <Button
                key={file}
                onClick={() => onClickFile(file)}
                variant="outlined"
                sx={{
                  display: 'block',
                  width: '50%',
                  marginBottom: 2,
                  padding: 2,
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ textTransform: 'lowercase' }}
                >
                  {version}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {dateFormatted}
                </Typography>
              </Button>
            );
          })}
      </Grid2>
      <Grid2 item="true" size={1} />
    </Grid2>
  );
};
export default ReleaseNotes;
