export const getStyles = (theme) => {
  return {
    tooltip: {
      background: 'linear-gradient(45deg, #388e3c, #81c784)',
      color: theme.palette.premium,
      borderRadius: '8px',
      padding: '10px',
      fontSize: '1rem',
    },
    registerEmailInfo: {
      emailText: {
        fontWeight: 'bold',
        color: theme.palette.premium,
      },
      message: {
        paddingHorizontal: 80,
        fontSize: 19,
        marginBottom: 2,
        textAlign: 'center',
      },
    },
  };
};
